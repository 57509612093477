import { FileInput } from '@/models/FileInput';
import jszip from 'jszip';

export function uuidv4(): string {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    //console.log((c == 'x' ? r : (r & 0x3 | 0x8)).toString(16));
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}
export function validateFile(files: File | File[]): boolean {
  const isXer = (f: File): boolean => {
      return f.name.toLowerCase().endsWith('.xer');
  };
  return Array.isArray(files) ? files.every((f) => isXer(f)) : isXer(files);
}

export async function zipFiles(inputs: FileInput[]): Promise<Blob> {
  const zipFile = new jszip();
  for (let i = 0; i < inputs.length; i++) {
      const fi = inputs[i];
      const folder = zipFile.folder(fi.inputName);
      const files = Array.isArray(fi.inputFiles)
          ? fi.inputFiles
          : [fi.inputFiles];
      for (let j = 0; j < files.length; j++) {
          const f = files[j];
          const data = await f.arrayBuffer();
          folder.file(f.name, data);
      }
  }
  return await zipFile.generateAsync({
      type: 'blob',
      compression: 'DEFLATE',
      compressionOptions: {
          level: 9,
      },
  });
}

export * from './strings';

import { nonEmptyString } from '@/utils';

function inputNameFromFile(file: File): string | undefined {
  if (file != null) {
    return file.name.split('.')[0].split('-')[0];
  }
}

/**
 * Class to model a single FileInput row item in the file upload form.
 */
export class FileInput {
  private _inputFiles: File | File[] | null;
  public inputName?: string | null;

  public get inputFiles(): File | File[] | null {
    return this._inputFiles;
  }
  public set inputFiles(files: File | File[] | null) {
    if (!nonEmptyString(this.inputName) && files != null) {
      if (Array.isArray(files)) {
        if (files.length > 0) {
          this.inputName = inputNameFromFile(files[0]);
        }
      } else {
        this.inputName = inputNameFromFile(files);
      }
    }
    this._inputFiles = files;
  }

  constructor() {
    this._inputFiles = null;
  }

  /**
   * Determines if this input item is valid.
   *
   * @param nameRequired - Indicates if the {@link FileInput.inputName} field is required; default is `false`.
   * @returns `true` if this input item is valid; otherwise, `false`
   */
  public isValid(nameRequired = false): boolean {
    if (this.inputFiles == null) {
      return false;
    }
    if (nameRequired && !nonEmptyString(this.inputName)) {
      return false;
    }
    return !Array.isArray(this.inputFiles) || this.inputFiles.length > 0;
  }
}

/**
 * Class to model all the input row items in the file upload form.
 */
export class FileInputItems {
  public nameRequired: boolean;
  public items: FileInput[];

  public get count(): number {
    return this.items.length;
  }

  public get validCount(): number {
    return this.items.filter((i) => i.isValid(this.nameRequired)).length;
  }

  public get allValid(): boolean {
    return this.count === this.validCount;
  }

  constructor(nameRequired = false) {
    this.nameRequired = nameRequired;
    this.items = [new FileInput()];
  }

  /**
   * Adds an empty {@link FileInput} item to the collection.
   */
  public add(): void {
    this.items.push(new FileInput());
  }

  /**
   * Removes the item at the specified index from the collection.
   *
   * @remarks
   * If the specified index is outside of the bounds of the collection then nothing is done and an empty array is returned.
   *
   * @param idx - The index of the item to remove from the collection.
   * @returns The deleted element(s) (if any).
   */
  public remove(idx: number): FileInput[] {
    if (idx >= this.count) {
      return [];
    }
    return this.items.splice(idx, 1);
  }

  /**
   * Clears the list of input items.
   *
   * @remarks
   * The state of the property {@link FileInputItems.nameRequired} is not changed by this method.
   */
  public clear(): void {
    this.items = [new FileInput()];
  }
}
